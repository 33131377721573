import React from 'react';
import { usePaymentInputs } from 'react-payment-inputs';

import Button from '../../components/Button';
import { Input } from '../../components/Input';

const PaymentForm = ({ onSubmit, onClickWhy }) => {
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();
  const [form, setForm] = React.useState({
    cardNumber: '',
    cardExpDate: '',
    cardCVC: '',
  });

  const hasError = meta.isTouched && meta.error;

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (hasError) return;

    // convert expiration date to expiration month / year
    const { cardExpDate } = form;
    const cardExpMonth = Number(cardExpDate.split('/')[0]);
    const cardExpYear = Number(cardExpDate.split('/')[1]);

    onSubmit({ ...form, cardExpMonth, cardExpYear });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div>
          <Input
            {...getCardNumberProps({ onChange: handleChange })}
            id="card-number"
            required
            type="text"
            name="cardNumber"
            label="Card number"
            value={form.cardNumber}
          />
        </div>
        <div className="pt-4 flex gap-4">
          <Input
            {...getExpiryDateProps({ onChange: handleChange })}
            id="card-exp-date"
            required
            type="text"
            name="cardExpDate"
            label="Expiration date"
            value={form.cardExpDate}
          />
          <Input
            {...getCVCProps({ onChange: handleChange })}
            id="card-cvc"
            required
            type="text"
            name="cardCVC"
            label="CVV"
            value={form.cardCVC}
          />
        </div>
        {hasError && (
          <div className="text-sm text-red-500 pt-2">{meta.error}</div>
        )}
        <p className="pt-6 text-sm leading-5 font-normal text-gray-700">
          To protect customers against fraudulent activity, a credit card number
          is required. Credit cards must be US issued only.{' '}
          <button
            className="font-bold text-indigo-400"
            type="button"
            onClick={onClickWhy}
          >
            Why do we need this?
          </button>
        </p>
        <p className="py-4 text-sm leading-5 font-semibold text-gray-800">
          A $1 authorization charge will be placed on your card to verify the
          validity of a credit card. No actual funds will be debited from your
          card.
        </p>
        <div className="py-2 flex justify-center">
          <Button type="submit" disabled={hasError}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default PaymentForm;
