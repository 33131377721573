import Content from '../../components/Content';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <Content>
        <h1 className="text-2xl font-bold tracking-tight text-gray-900 text-center sm:text-4xl">
          Privacy Policy
        </h1>
        <p className="mt-4 text-lg leading-8 text-gray-700 sm:mt-6">
          At Cleo Cards, Inc. ("Cleo Cards", "we", "us", or "our"), we are
          committed to protecting your privacy and ensuring the security of your
          personal information. This Privacy Policy outlines the types of
          personal information we collect, how we use and protect that
          information, and your rights in relation to that information.
        </p>
        <h2 className="text-xl font-bold tracking-tight text-gray-900 mt-6 mb-2 sm:text-2xl">
          Information We Collect
        </h2>
        <p className="leading-7 text-gray-700">
          When you use Cleo Cards, we may collect the following types of
          personal information:
        </p>
        <ul className="list-disc leading-7 mt-2 ml-6 text-gray-700">
          <li className="ml-4">Information you provide to us</li>
          <li className="ml-4">Information we collect automatically</li>
          <li className="ml-4">Information we receive from third parties</li>
        </ul>
        <h2 className="text-xl font-bold tracking-tight text-gray-900 mt-6 mb-2 sm:text-2xl">
          How We Use Your Information
        </h2>
        <p className="leading-7 text-gray-700">
          We may use the personal information we collect for the following
          purposes:
        </p>
        <ul className="list-disc leading-7 mt-2 ml-6 text-gray-700">
          <li className="ml-4">To provide our services to you</li>
          <li className="ml-4">
            To improve and personalize our services and to develop new products
            and features
          </li>
          <li className="ml-4">
            To communicate with you about our services, including sending you
            updates and notifications
          </li>
          <li className="ml-4">
            To comply with legal obligations or to protect our rights and
            interests
          </li>
          <li className="ml-4">
            To prevent, detect, or investigate potential fraud, security
            breaches, or other prohibited or illegal activities
          </li>
        </ul>
        <h2 className="text-xl font-bold tracking-tight text-gray-900 mt-6 mb-2 sm:text-2xl">
          How We Protect Your Information
        </h2>
        <p className="leading-7 text-gray-700">
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. We use industry-standard
          security measures, such as encryption and firewalls, to protect your
          information. We also limit access to your personal information to only
          those employees, contractors, and service providers who need access to
          perform their jobs.
        </p>
        <h2 className="text-xl font-bold tracking-tight text-gray-900 mt-6 mb-2 sm:text-2xl">
          Your Rights
        </h2>
        <p className="leading-7 text-gray-700">
          You have certain rights with respect to your personal information,
          including the right to:
        </p>
        <ul className="list-disc leading-7 mt-2 ml-6 text-gray-700">
          <li className="ml-4">
            Access and receive a copy of the personal information we hold about
            you
          </li>
          <li className="ml-4">
            Request correction or deletion of your personal information
          </li>
          <li className="ml-4">
            Object to or restrict our processing of your personal information
          </li>
          <li className="ml-4">
            Withdraw your consent at any time (if we are processing your
            personal information based on your consent)
          </li>
        </ul>
        <p className="leading-7 text-gray-700">
          To exercise any of these rights, please contact us using the contact
          details provided below.
        </p>
        <h2 className="text-xl font-bold tracking-tight text-gray-900 mt-6 mb-2 sm:text-2xl">
          Changes to this Privacy Policy
        </h2>
        <p className="leading-7 text-gray-700">
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or to comply with legal requirements. If we make
          material changes to this Privacy Policy, we will notify you by posting
          a notice on our website or by sending you an email.
        </p>
        <h2 className="text-xl font-bold tracking-tight text-gray-900 mt-6 mb-2 sm:text-2xl">
          Contact Us
        </h2>
        <p className="leading-7 text-gray-700">
          If you have any questions or concerns about this Privacy Policy or our
          practices with respect to your personal information, please contact us
          at <a href="mailto:info@cleocards.com">info@cleocards.com</a>.
        </p>
      </Content>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
