import React from 'react';

import { Wrapper, Input, InputLabel } from './styles';

const InputWithLabel = React.forwardRef(({ id, label, ...props }, ref) => (
  <Wrapper>
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <Input id={id} {...props} ref={ref} />
  </Wrapper>
));

export default InputWithLabel;
