import { DateTime } from 'luxon';

export const getFullDate = (isoDate) => {
  const dt = DateTime.fromISO(isoDate);
  return dt.toLocaleString(DateTime.DATE_FULL);
};

export const isValidDate = (date) => {
  const timestamp = Date.parse(date);
  return !isNaN(timestamp);
};

export const isPastDate = (date) => {
  const timestamp = Date.parse(date);
  return Date.now() > timestamp;
};
