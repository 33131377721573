import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

import Combobox from '../../components/Combobox';
import { centsToDollars } from '../../utils/currency';

const CardTable = ({ codes, stores, currentStore = null }) => {
  const navigate = useNavigate();
  const [selectedStore, setSelectedStore] = React.useState(currentStore);
  const [filteredCodes, setFilteredCodes] = React.useState([]);
  const [finalCodes, setFinalCodes] = React.useState([]);
  const [sortFilter, setSortFilter] = React.useState({
    // field: 'someField',
    // ascending: true,
  });

  // TODO: improve. also, there's a bug that doesn't show the selected checkmark
  React.useEffect(() => {
    setSelectedStore(currentStore);
  }, [currentStore]);

  React.useEffect(() => {
    const filtered = selectedStore
      ? codes.filter((c) => c.storeId === selectedStore.id)
      : codes;

    setFilteredCodes(filtered);

    // reset sorting
    setSortFilter({});
  }, [codes, selectedStore]);

  React.useEffect(() => {
    const { field, ascending } = sortFilter;

    if (field) {
      const newCodes = filteredCodes.slice();

      if (ascending) {
        newCodes.sort((a, b) => (a[field] < b[field] ? -1 : 1));
      } else {
        newCodes.sort((a, b) => (a[field] > b[field] ? -1 : 1));
      }

      setFinalCodes(newCodes);
    } else {
      setFinalCodes(filteredCodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortFilter]);

  const handleChangeStore = (store) => {
    if (store) {
      navigate(`/buy-gift-cards/${store.id}`);
    } else {
      navigate('/buy-gift-cards');
    }

    setSelectedStore(store);
  };

  const handleSort = (field) => {
    if (sortFilter.field === field) {
      if (sortFilter.ascending) {
        setSortFilter({ field, ascending: false });
      } else {
        setSortFilter({});
      }
    } else {
      setSortFilter({ field, ascending: true });
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="mb-4 w-56 sm:w-64">
              <Combobox
                options={stores}
                selectedOption={selectedStore}
                label="Search by brands"
                onChange={handleChangeStore}
              />
            </div>
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      <button
                        className="group flex items-center"
                        onClick={() => handleSort('storeTitle')}
                      >
                        Brand Name
                        {sortFilter.field === 'storeTitle' &&
                          (sortFilter.ascending ? (
                            <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                              <ChevronUpIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : (
                            <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                              <ChevronDownIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ))}
                      </button>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <button
                        className="group flex items-center"
                        onClick={() => handleSort('balance')}
                      >
                        Card Value
                        {sortFilter.field === 'balance' &&
                          (sortFilter.ascending ? (
                            <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                              <ChevronUpIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : (
                            <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                              <ChevronDownIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ))}
                      </button>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <button
                        className="group flex items-center"
                        onClick={() => handleSort('discount')}
                      >
                        Discount
                        {sortFilter.field === 'discount' &&
                          (sortFilter.ascending ? (
                            <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                              <ChevronUpIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : (
                            <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                              <ChevronDownIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ))}
                      </button>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <button
                        className="group flex items-center"
                        onClick={() => handleSort('price')}
                      >
                        Your Price
                        {sortFilter.field === 'price' &&
                          (sortFilter.ascending ? (
                            <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                              <ChevronUpIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : (
                            <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                              <ChevronDownIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ))}
                      </button>
                    </th>
                    {/* <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Buy</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {finalCodes.map(
                    ({ id, storeTitle, balance, discount, price }) => (
                      <tr key={id} className="hover:bg-violet-50">
                        <td className="whitespace-nowrap py-2.5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {storeTitle}
                        </td>
                        <td className="whitespace-nowrap px-3 py-2.5 text-sm text-gray-900">
                          {centsToDollars(balance)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-2.5 text-sm text-gray-900">
                          <span className="inline-block border rounded-2xl border-gray-200 px-4 py-1">
                            <div className="inline-block bg-green-500 w-1.5 h-1.5 rounded-md align-middle" />
                            <span className="align-middle ml-1.5">
                              {discount.toFixed(0)}% off
                            </span>
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-2.5 text-sm text-gray-900">
                          {centsToDollars(price)}
                        </td>
                        {/* <td className="relative whitespace-nowrap py-2.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button className="rounded-full bg-violet-50 p-4 hover:bg-violet-100">
                            <ShoppingCartIcon
                              className="h-6 w-6 text-indigo-600"
                              aria-hidden="true"
                            />
                          </button>
                        </td> */}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardTable;
