import React from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Button from '../../components/Button';
import Content from '../../components/Content';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import { BreadcrumbHeader } from '../../components/PageHeader';
import { Input } from '../../components/Input';
import { useNotification } from '../../components/Notification';

import {
  getAllStores,
  getCode,
  listCode,
  sellCode,
  sellUnsupportedCode,
} from '../../api';
import { centsToDollarsRaw, dollarsToCents } from '../../utils/currency';
import { getStoreImage } from '../../utils/image';

import VerificationModal from './VerificationModal';
import ContentWrapper from './styles';

const SELLING_PRICE_PERCENT = 0.85;
const EARNING_PERCENT = 0.88;

export async function loader({ params }) {
  const stores = await getAllStores(true);
  const store = stores.find((s) => s.id === params.storeId);
  return { store };
}

const SellCard = () => {
  const navigate = useNavigate();
  const showNotification = useNotification();
  const { codeId } = useParams();
  const { store } = useLoaderData();
  const { getAccessTokenSilently, loginWithRedirect, user, isLoading } =
    useAuth0();

  const [formData, setFormData] = React.useState({
    storeId: store.id,
    code: '',
    pin: '',
    balance: '',
    price: '',
  });
  const [verificationModalOpen, setVerificationModalOpen] =
    React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  React.useEffect(() => {
    if (codeId) {
      getAccessTokenSilently()
        .then((token) => getCode(token, codeId))
        .then((code) => {
          setFormData({
            storeId: code.storeId,
            code: code.code,
            pin: code.pin,
            balance: centsToDollarsRaw(code.balance),
            price: centsToDollarsRaw(code.price),
          });
        });
    }
  }, [codeId, getAccessTokenSilently]);

  const earning = React.useMemo(
    () => Number(formData.price || 0) * EARNING_PERCENT,
    [formData.price]
  );

  const pageTitle = React.useMemo(
    () => `${store.title} Gift Card`,
    [store.title]
  );
  const breadcrumbItems = React.useMemo(
    () => [
      { to: '/', label: 'Home' },
      { to: '/sell-gift-cards', label: 'Sell' },
      { to: '#', label: pageTitle },
    ],
    [pageTitle]
  );

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (!isLoading && !user) {
      // if user's not authenticated, force it
      loginWithRedirect({
        appState: {
          returnTo: window.location.href,
        },
      });
    }

    const newFormData = { ...formData, [id]: value };

    if (id === 'balance' && value) {
      newFormData.price = (Number(value) * SELLING_PRICE_PERCENT).toFixed(2);
    }

    setFormData(newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.balance && Number(formData.price) > Number(formData.balance)) {
      return showNotification({
        title: 'Invalid Selling Price',
        description:
          'Oops, it looks like your selling price is higher than the balance on this gift card. Please adjust your selling price so it is less than the balance.',
        type: 'error',
      });
    }

    try {
      setProcessing(true);

      const token = await getAccessTokenSilently();
      const data = {
        ...formData,
        balance: dollarsToCents(formData.balance),
        price: dollarsToCents(formData.price),
        email: user.email,
      };

      if (codeId) {
        data.codeId = codeId;
        await handleListCode(token, data);
      } else {
        await handleSellCode(token, data);
      }
    } catch (error) {
      console.log(error);

      const { type } = error.response?.data || {};

      if (type === 'ERR_CODE_NOT_VALID') {
        return showNotification({
          title: 'Code not valid',
          description:
            'We can not validate the Store credit code you inputted. Please check the code and try again',
          type: 'error',
        });
      } else if (type === 'ERR_CODE_BALANCE_MISMATCH') {
        return showNotification({
          title: 'Incorrect balance',
          description:
            'The balance you inputed does not match the value of the card. Please check the balance and try again',
          type: 'error',
        });
      } else if (type === 'ERR_CODE_INVALID_PRICE') {
        return showNotification({
          title: 'Invalid Selling Price',
          description:
            'Oops, it looks like your selling price is higher than the balance on this gift card. Please adjust your selling price so it is less than the balance.',
          type: 'error',
        });
      }

      return showNotification({
        title: 'Error',
        description: 'Sorry something went wrong!',
        type: 'error',
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleListCode = async (token, data) => {
    if (store.supported) {
      await listCode(token, data);
      showNotification({
        title: 'Successfully uploaded!',
        description: 'Congratulations your card is now live!',
      });

      navigate('/cards');
    } else {
      setVerificationModalOpen(true);
    }
  };

  const handleSellCode = async (token, data) => {
    if (store.supported) {
      await sellCode(token, data);
      showNotification({
        title: 'Successfully listed!',
        description:
          'Congratulations your card is now listed! We will do the rest and notify you once it’s sold!',
      });

      navigate('/cards');
    } else {
      setVerificationModalOpen(true);
    }
  };

  const handleSubmitVerification = async (paymentDetails) => {
    setVerificationModalOpen(false);
    setProcessing(true);

    try {
      const token = await getAccessTokenSilently();
      const data = {
        ...formData,
        balance: dollarsToCents(formData.balance),
        price: dollarsToCents(formData.price),
        paymentDetails,
        email: user.email,
      };

      if (codeId) data.codeId = codeId;

      await sellUnsupportedCode(token, data);

      navigate('/cards');
    } catch (error) {
      console.log(error);

      showNotification({
        title: 'Error',
        description: 'Sorry something went wrong!',
        type: 'error',
      });
    }

    setProcessing(false);
  };

  return (
    <>
      <Navbar />
      <Content>
        <ContentWrapper>
          <BreadcrumbHeader items={breadcrumbItems} title={pageTitle} />
          <div className="content-body">
            <div className="left">
              <img src={getStoreImage(store.imageUrl)} alt="Gift Card" />
            </div>
            <form className="right" onSubmit={handleSubmit}>
              <div className="heading">
                <h3>Gift Card Information</h3>
                <p>We do not accept cards that expire or incur fees.</p>
              </div>
              <div className="code-container">
                <Input
                  id="code"
                  label="Gift Card Serial Number *"
                  required
                  value={formData.code}
                  onChange={handleChange}
                />
                {!store.isShopify && (
                  <Input
                    id="pin"
                    label="PIN"
                    value={formData.pin}
                    onChange={handleChange}
                  />
                )}
              </div>
              <div className="highlight-box">
                <h3>eGift Card</h3>
                <p>
                  eliminates shipping of a physical gift card and reduces your
                  listing fees. Please note that many eGift cards are for online
                  use only.
                </p>
              </div>
              <div className="prices-container">
                <Input
                  id="balance"
                  label="Current Balance"
                  type="number"
                  step=".01"
                  value={formData.balance}
                  onChange={handleChange}
                />
                <Input
                  id="price"
                  label="Your Selling Price *"
                  required
                  type="number"
                  step=".01"
                  value={formData.price}
                  onChange={handleChange}
                />
              </div>
              <div className="highlight-box">
                <h3>
                  At this selling price, you'll earn{' '}
                  <span className="accented">${earning.toFixed(2)}</span> for
                  your gift card.
                </h3>
              </div>
              <div className="submit-container">
                <p>
                  By submitting this listing I fully agree with Terms and
                  Conditions
                </p>
                <Button
                  disabled={processing}
                  processing={processing}
                  type="submit"
                >
                  {processing ? 'Processing...' : 'Continue Listing'}
                </Button>
              </div>
            </form>
          </div>
        </ContentWrapper>
        <VerificationModal
          open={verificationModalOpen}
          onOpen={setVerificationModalOpen}
          onSubmit={handleSubmitVerification}
        />
      </Content>
      <Footer />
    </>
  );
};

export default SellCard;
