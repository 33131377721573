import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${({ isStoreCard }) => (isStoreCard ? 400 : 350)}px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  text-align: left;

  .card-img {
    height: 192px;
    border-radius: 8px;
  }

  .bottom-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 24px;
  }

  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #111827;
  }

  .store-title {
    margin-bottom: 8px;
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6b7280;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .price {
    margin-top: 4px;
  }
`;
