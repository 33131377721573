import React from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox as HUCombobox } from '@headlessui/react';

import { classNames } from '../../utils/dom';

const newOption = {
  title: 'Create new...',
};

const Combobox = ({
  options,
  label,
  selectedOption,
  allowNewOption,
  onChange,
}) => {
  const [query, setQuery] = React.useState('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.title.toLowerCase().includes(query.toLowerCase());
        });

  const handleChangeQuery = (event) => {
    const { value } = event.target;
    setQuery(value);

    if (!value) {
      onChange(null);
    }
  };

  const handleChangeOption = (option) => {
    if (option !== newOption) {
      onChange(option);
    } else {
      onChange({ ...newOption, title: query });
    }
  };

  return (
    <HUCombobox as="div" value={selectedOption} onChange={handleChangeOption}>
      <HUCombobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </HUCombobox.Label>
      <div className="relative mt-2">
        <HUCombobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={handleChangeQuery}
          displayValue={(option) =>
            option === newOption ? query : option?.title
          }
        />
        <HUCombobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </HUCombobox.Button>

        <HUCombobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {filteredOptions.map((option) => (
            <HUCombobox.Option
              key={option.id}
              value={option}
              className={({ active }) =>
                classNames(
                  'relative cursor-pointer select-none py-2 pl-8 pr-4',
                  active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                )
              }
            >
              {({ active, selected }) => (
                <>
                  <span
                    className={classNames(
                      'block truncate',
                      selected && 'font-semibold'
                    )}
                  >
                    {option.title}
                  </span>

                  {selected && (
                    <span
                      className={classNames(
                        'absolute inset-y-0 left-0 flex items-center pl-1.5',
                        active ? 'text-white' : 'text-indigo-600'
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </HUCombobox.Option>
          ))}
          {!filteredOptions.length && allowNewOption && (
            <HUCombobox.Option
              value={newOption}
              className={({ active }) =>
                classNames(
                  'relative cursor-pointer select-none py-2 pl-8 pr-4',
                  active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                )
              }
            >
              <span className="block truncate">{newOption.title}</span>
            </HUCombobox.Option>
          )}
        </HUCombobox.Options>
      </div>
    </HUCombobox>
  );
};

export default Combobox;
