const PageHeader = ({ title, subtitle }) => {
  return (
    <div className="py-16 px-6 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          {title}
        </h2>
        <p className="mt-4 text-lg leading-8 text-gray-500 sm:mt-6">
          {subtitle}
        </p>
      </div>
    </div>
  );
};

export default PageHeader;
