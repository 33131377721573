import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import Button from '../../components/Button';

const WhyModal = ({ open, onOpen }) => {
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="flex justify-between w-full p-6">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-7 font-medium text-gray-900"
                    >
                      Why do we need this?
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => onOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="p-6 pt-0">
                    <p className="mb-4 text-gray-500">
                      <span className="font-medium text-gray-900">
                        Identity Verification:
                      </span>{' '}
                      We are all safer when we are who we say we are. Your
                      credit card helps confirm your identity, protecting our
                      community and helping us offer some of the best payouts in
                      the gift card exchange industry.
                    </p>
                    <p className="mb-4 text-gray-500">
                      <span className="font-medium text-gray-900">
                        Authorization Charge:
                      </span>{' '}
                      A $1 authorization charge will be placed on your card.
                      This is a standard method of verifying the validity of a
                      credit card. No actual funds will be debited from your
                      card.
                    </p>
                    <p className="mb-4 text-gray-500">
                      <span className="font-medium text-gray-900">
                        Fraud Protection:
                      </span>{' '}
                      Your card will only be charged if we determine that the
                      gift cards were obtained fraudulently or if an attempt is
                      made to use the cards after completing this transaction.
                      This is the best way of ensuring that only legitimate
                      customers like yourself make it through our exchange
                      process.
                    </p>
                    <p className="mb-4 text-gray-500">
                      <span className="font-medium text-gray-900">
                        You are protected:
                      </span>{' '}
                      Your credit card information is processed through Stripe.
                      It is never saved on our servers.
                    </p>
                    <div className="py-2 flex justify-center">
                      <Button onClick={() => onOpen(false)}>OK</Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default WhyModal;
