import { classNames } from '../../utils/dom';

const Button = ({ children, processing, ...props }) => (
  <div className="relative flex-1 max-w-[16rem] rounded-md overflow-hidden">
    <button
      type="button"
      className="w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:hover:bg-indigo-600"
      {...props}
    >
      {children}
    </button>
    <div
      className={classNames(
        'absolute top-0 left-0 w-0 h-10 bg-white opacity-40 transition-[width] duration-[20000ms]',
        processing ? 'w-full' : 'invisible'
      )}
    ></div>
  </div>
);

export default Button;
