const SectionHeading = ({ children }) => {
  return (
    <div className="border-b border-gray-200 pb-5">
      <h3 className="text-base font-medium leading-6 text-gray-900">
        {children}
      </h3>
    </div>
  );
};

export default SectionHeading;
