import { useRouteError } from 'react-router-dom';

import Content from '../../components/Content';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

import { ContentWrapper } from './styles';

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <>
      <Navbar />
      <Content>
        <ContentWrapper>
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
        </ContentWrapper>
      </Content>
      <Footer />
    </>
  );
};

export default ErrorPage;
