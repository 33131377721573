import { classNames } from '../../utils/dom';

const Badge = ({ label, type = 'info' }) => {
  let className;

  switch (type) {
    case 'info':
      className = 'bg-purple-100 text-purple-800';
      break;
    case 'success':
      className = 'bg-green-100 text-green-800';
      break;
    case 'warning':
      className = 'bg-yellow-100 text-yellow-800';
      break;
    case 'danger':
      className = 'bg-red-100 text-red-800';
      break;
    default:
      className = '';
      break;
  }

  return (
    <>
      <span
        className={classNames(
          'inline-flex items-center rounded-md px-2.5 py-0.5 text-sm font-medium',
          className
        )}
      >
        {label}
      </span>
    </>
  );
};

export default Badge;
