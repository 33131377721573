import styled from 'styled-components';

const ContentWrapper = styled.div`
  .content-body {
    margin: 40px 0;
    display: flex;
    gap: 33px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .left {
      width: 275px;
      min-width: 275px;
    }

    .right {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    .heading {
      padding-bottom: 20px;
      border-bottom: 1px solid #e5e7eb;
    }

    .highlight-box {
      background: #f9fafb;
      border-radius: 8px;
      padding: 24px;

      .accented {
        color: #10b981;
      }

      p {
        width: 66%;

        @media (max-width: 1024px) {
          width: 100%;
        }
      }
    }

    .code-container {
      display: flex;
      gap: 32px;

      @media (max-width: 480px) {
        flex-direction: column;
      }

      div:first-child {
        max-width: 65%;
        flex: 2;

        @media (max-width: 480px) {
          max-width: 100%;
        }
      }

      div {
        flex: 1;
      }
    }

    .half-container {
      > * {
        width: 50%;

        @media (max-width: 480px) {
          width: 100%;
        }
      }
    }

    .prices-container {
      display: flex;
      gap: 24px;

      @media (max-width: 480px) {
        flex-direction: column;
      }
    }

    .submit-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;

      @media (max-width: 1024px) {
        flex-direction: column;
      }
    }

    img {
      width: 100%;
    }

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #111827;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6b7280;
      margin-top: 8px;
    }
  }
`;

export default ContentWrapper;
