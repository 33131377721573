import React from 'react';

import Badge from '../Badge';
import { getStoreImage } from '../../utils/image';
import { Wrapper } from './styles';

const Card = ({
  isStoreCard,
  storeTitle,
  storeSubtitle,
  storeImageUrl,
  // supported,
  balance,
  rejected,
  purchased,
  used,
  listed,
  pending,
  actionButtons,
}) => {
  const renderBadge = () => {
    if (rejected) {
      return <Badge label="Rejected" type="danger" />;
    }

    if (purchased) {
      return <Badge label="Sold" type="info" />;
    }

    if (used) {
      return <Badge label="Used" type="info" />;
    }

    if (pending) {
      return <Badge label="Under Review" type="warning" />;
    }

    // if (!supported) {
    //   return <Badge label="Not Sellable on Cleo" type="warning" />;
    // }

    if (listed) {
      return <Badge label="Listed for Sale" type="success" />;
    }

    return null;
  };

  if (isStoreCard) {
    return (
      <Wrapper isStoreCard>
        <img
          className="card-img"
          src={getStoreImage(storeImageUrl)}
          alt="Card"
        />
        <div className="bottom-section">
          <div>
            <p className="heading store-title">{storeTitle}</p>
            <p className="description" title={storeSubtitle}>
              {storeSubtitle}
            </p>
          </div>
          {actionButtons}
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <img className="card-img" src={getStoreImage(storeImageUrl)} alt="Card" />
      <div className="absolute top-4 left-4">{renderBadge()}</div>
      <div className="bottom-section">
        <div>
          <p className="heading store-title">{storeTitle}</p>
          {balance && <p className="heading balance">{balance}</p>}
        </div>
        {actionButtons}
      </div>
    </Wrapper>
  );
};

export default Card;
