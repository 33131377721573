export const dollarsToCents = (dollars = 0) => {
  return (Number(dollars) * 100).toFixed(0);
};

export const centsToDollars = (cents = 0) => {
  return `$${(cents / 100).toFixed(2)}`;
};

export const centsToDollarsRaw = (cents = 0) => {
  return `${(cents / 100).toFixed(2)}`;
};
