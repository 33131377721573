import React from 'react';
import styled from 'styled-components';

import SearchIcon from '../../assets/icons/search.png';
import { Input } from './styles';

const Wrapper = styled.div`
  position: relative;

  img {
    position: absolute;
    top: 11px;
    left: 15px;
  }
`;

const SearchInput = (props) => (
  <Wrapper>
    <img src={SearchIcon} alt="Search" />
    <Input {...props} style={{ paddingLeft: 40 }} />
  </Wrapper>
);

export default SearchInput;
