import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  XMarkIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
} from '@heroicons/react/24/outline';

import Alert from '../../components/Alert';
import Badge from '../../components/Badge';
import { ConfirmModal } from '../../components/Modal';

import { getFullDate } from '../../utils/date';
import { classNames } from '../../utils/dom';
import { getStoreImage } from '../../utils/image';

const CardModal = ({ open, onOpen, onSell, onRemove, onUnlist, details }) => {
  const [copied, setCopied] = React.useState(false);
  const [removeModalOpen, setRemoveModalOpen] = React.useState(false);
  const [unlistModalOpen, setUnlistModalOpen] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  React.useEffect(() => {
    setCopied(false);
  }, [open]);

  if (!details) return null;

  const {
    storeTitle,
    storeImageUrl,
    storeUrl,
    code,
    expiresAt,
    balance,
    rejected,
    purchased,
    used,
    listed,
    // supported,
    pending,
  } = details;

  const handleCopyCode = () => {
    navigator.clipboard.writeText(code);
    setCopied(true);
  };

  const handleRemove = async () => {
    setProcessing(true);
    await onRemove();
    setRemoveModalOpen(false);
    setProcessing(false);
  };

  const handleUnlist = async () => {
    setProcessing(true);
    await onUnlist();
    setUnlistModalOpen(false);
    setProcessing(false);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                  <div className="flex justify-between w-full p-6">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-7 font-medium text-gray-900"
                    >
                      Card Details
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => onOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <img
                    className="w-full aspect-2/1 object-cover"
                    src={getStoreImage(storeImageUrl)}
                    alt="Gift Card"
                  />
                  <div className="p-6">
                    <div className="flex justify-between items-center">
                      <p className="text-2xl leading-8 font-bold text-gray-900">
                        {balance}
                      </p>
                      {rejected && <Badge label="Rejected" type="danger" />}
                      {purchased && <Badge label="Sold" type="info" />}
                      {used && <Badge label="Used" type="info" />}
                      {listed && (
                        <Badge label="Listed for Sale" type="success" />
                      )}
                    </div>
                    <p className="text-sm leading-5 font-normal text-gray-500">
                      {storeTitle}
                    </p>
                    {!purchased && (
                      <div className="flex align-center justify-between mt-5 p-4 bg-gray-200">
                        <span className="text-sm leading-none font-normal">
                          {code}
                        </span>
                        <button onClick={handleCopyCode}>
                          {copied ? (
                            <ClipboardDocumentCheckIcon
                              className="h-4 w-4"
                              aria-hidden="true"
                            />
                          ) : (
                            <ClipboardDocumentIcon
                              className="h-4 w-4"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </div>
                    )}
                    {rejected && (
                      <div className="mt-5">
                        <Alert label="Your card was rejected after review by our team. Please update the card details and re-submit." />
                      </div>
                    )}
                    {!used && pending && (
                      <div className="mt-5">
                        <Alert label="Your card is currently being verified by our team." />
                      </div>
                    )}
                    {/* {!supported && (
                      <div className="mt-5">
                        <Alert label="You can add gift cards from this store to your Cleo wallet, but they cannot be listed for sale on the platform yet." />
                      </div>
                    )} */}
                    <div className="flex gap-2.5 mt-5">
                      {!purchased && !listed && !pending && !rejected && (
                        <button
                          type="button"
                          // disabled={!supported}
                          className={classNames(
                            'rounded-md px-3 py-2 text-sm font-medium shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex-1',
                            true // supported
                              ? 'text-white bg-indigo-600 hover:bg-indigo-500'
                              : 'text-gray-600 bg-gray-300'
                          )}
                          onClick={onSell}
                        >
                          List for Sale
                        </button>
                      )}
                      {rejected && (
                        <button
                          type="button"
                          className={classNames(
                            'rounded-md px-3 py-2 text-sm font-medium shadow-sm text-white bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex-1'
                          )}
                          onClick={onSell}
                        >
                          Update Card
                        </button>
                      )}
                      {listed && (
                        <button
                          type="button"
                          className="rounded-md bg-red-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 flex-1"
                          onClick={() => setUnlistModalOpen(true)}
                        >
                          Remove from Sale
                        </button>
                      )}
                      {(used || purchased || !pending) && (
                        <button
                          type="button"
                          // disabled={!supported}
                          className={classNames(
                            'rounded-md px-3 py-2 text-sm font-medium shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 flex-1',
                            true // supported
                              ? 'text-white bg-indigo-600 hover:bg-indigo-500'
                              : 'text-gray-600 bg-gray-300'
                          )}
                          onClick={() => setRemoveModalOpen(true)}
                        >
                          Remove from Wallet
                        </button>
                      )}
                    </div>
                    {storeUrl && (
                      <div className="mt-6">
                        <p className="text-sm leading-5 font-medium text-gray-500">
                          Website
                        </p>
                        <p className="mt-1 text-sm leading-5 font-normal text-gray-900">
                          {storeUrl}
                        </p>
                      </div>
                    )}
                    {expiresAt && (
                      <div className="mt-6">
                        <p className="text-sm leading-5 font-medium text-gray-500">
                          Expiration date
                        </p>
                        <p className="mt-1 text-sm leading-5 font-normal text-gray-900">
                          {getFullDate(expiresAt)}
                        </p>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ConfirmModal
        open={removeModalOpen}
        onOpen={setRemoveModalOpen}
        processing={processing}
        onConfirm={handleRemove}
        description="Are you sure you want to remove this card?"
        confirmButtonTitle="Remove"
      />
      <ConfirmModal
        open={unlistModalOpen}
        onOpen={setUnlistModalOpen}
        processing={processing}
        onConfirm={handleUnlist}
        description="Are you sure you want to unlist this card?"
        confirmButtonTitle="Unlist"
      />
    </>
  );
};

export default CardModal;
