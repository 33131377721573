import React from 'react';

import { Wrapper, Input } from './styles';

const InputWithoutLabel = React.forwardRef(({ id, ...props }, ref) => (
  <Wrapper>
    <Input large id={id} {...props} ref={ref} />
  </Wrapper>
));

export default InputWithoutLabel;
