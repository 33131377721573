import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

export const BreadcrumbHeader = ({
  items,
  title,
  subtitle,
  subtitleEl,
  actionButtonTitle,
  actionButtonLink,
}) => (
  <div>
    <div>
      <nav className="sm:hidden" aria-label="Back">
        <Link
          to="/"
          className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          <ChevronLeftIcon
            className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          Back
        </Link>
      </nav>
      <nav className="hidden sm:flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          {items.map(({ to, label }, index) => {
            if (index === 0) {
              return (
                <li key={label}>
                  <div className="flex">
                    <Link
                      to={to}
                      className="text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      {label}
                    </Link>
                  </div>
                </li>
              );
            }

            return (
              <li key={label}>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <Link
                    to={to}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    {label}
                  </Link>
                </div>
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
    <div className="mt-2 md:flex md:items-center md:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {title}
        </h2>
        {subtitle && <p className="mt-2 text-base text-gray-500">{subtitle}</p>}
        {!!subtitleEl && subtitleEl}
      </div>
      {actionButtonTitle && (
        <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <Link
            type="button"
            to={actionButtonLink}
            className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {actionButtonTitle}
          </Link>
        </div>
      )}
    </div>
  </div>
);

export default BreadcrumbHeader;
