import React from 'react';

import { Wrapper, MaskedInput, InputLabel } from './styles';

const DateInput = ({ id, label, ...props }) => (
  <Wrapper>
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <MaskedInput
      id={id}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      guide={false}
      placeholder="MM/DD/YYYY"
      {...props}
    />
  </Wrapper>
);

export default DateInput;
